<template>
<CModalExtended
  :title="getTitle"
  color="dark"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-extended"
  size="lg"
  
>
<loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12" lg="12" xl="12">
        <CCard class="card-simple">
          <CCardBody >
            <CRow  class="mb-4">
              
              <CCol sm="12" lg="12" xl="12">
                <CRow>
                    <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      v-model.trim="$v.actividadPortuaria.tpOperationId.$model"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.tpOperationId)"
                      :is-valid="hasError($v.actividadPortuaria.tpOperationId)"
                      :label="$t('label.OperationType')"
                      addLabelClasses="required text-right"
                      :options="tpOperationFormatted"
                    />
                  </CCol>
                    <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      v-model.trim="$v.actividadPortuaria.PortActivityId.$model"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :invalid-feedback="errorMessage($v.actividadPortuaria.PortActivityId)"
                      :is-valid="hasError($v.actividadPortuaria.PortActivityId)"
                      :label="$t('label.portActivity')"
                      addLabelClasses="required text-right"
                      :options="activityListFormatted"
                    />
                  </CCol>
                  <CCol sm="12" lg="12" xl="12" class="pl-0">
                    <CSelect
                      :value.sync="actividadPortuaria.Status"
                      :is-valid="statusSelectColor"
                      :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-7 col-xl-7'}"
                      :label="$t('label.status')"
                      v-model.trim="actividadPortuaria.Status"
                      :options="statusOptions"
                      addLabelClasses="required text-right"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
       <template #footer>
      <CButton
        color="add"
        class="m-2"
        :disabled="isSubmit"
        @click="changeStatus()"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept') }}</span>
      </CButton>
      <CButton
        color="wipe"
        class="m-2"
        :disabled="isSubmit"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel') }}</span>
      </CButton>
    </template>
</CModalExtended>
</template>

<script>
import tpOperationActividadValidations from '@/_validations/actividad-portuaria/tpOperationActividadFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import ActividadPortuariaMixin from '@/_mixins/actividad-portuaria';
import ModalMixin from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoadingActivitys: false,
    isLoadingTpClient: false,
    activityList: [], 
    tpOperationList: [], 
    actividadPortuaria: {
      PortActivityTpOperationId: '',
      PortActivityId: '',
      tpOperationId:'',
      OperationNameEs:'',
      Status: 1,
      statusOrigin: 1,
    },
    loadingOverlay: false,
    titulo: '',
  
  }
}

//Methods
function submit() {

  try {
    this.isSubmit = true;
    this.loadingOverlay = true;
    this.$v.actividadPortuaria.$touch();

    if (this.$v.actividadPortuaria.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
    }
  
    let PortActivityOperationJson = {

      PortActivityOperationId:this.actividadPortuaria.PortActivityTpOperationId,
      OperationId: this.actividadPortuaria.tpOperationId,
      PortActivityId: this.actividadPortuaria.PortActivityId,
      Status:  this.actividadPortuaria.Status, 
    };



    this.$http.put('PortActivityOperation-update', PortActivityOperationJson, { root: 'PortActivityOperationJson'} )
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.loadingOverlay = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }

}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  this.modalActive = newVal;
}

function resetForm() {
  this.actividadPortuaria.PortActivityTpOperationId = '';
  this.actividadPortuaria.PortActivityId = '';
  this.actividadPortuaria.tpOperationId = '';
  this.actividadPortuaria.OperationNameEs = '';
  this.actividadPortuaria.Status = 1;
  this.actividadPortuaria.statusOrigin = 1;
  this.$v.$reset();
}


function statusSelectColor() {
  return (this.actividadPortuaria.Status === 1)?true:false;
}


function getTitle() {

  return this.titulo;
}

function getActivityPort() {
  this.isLoadingActivitys = true;

  this.$http.get("PortActivity-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.activityList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingActivitys = false;
  });
}

  function changeStatus() {
    if(this.actividadPortuaria.statusOrigin !== this.actividadPortuaria.Status){              
      this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')+' '} ${this.actividadPortuaria.OperationNameEs}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }


function activityListFormatted(){

 let _lang = this.$i18n.locale;

  if(this.activityList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.activityList.map(function(e){
      chart.push({
        value: e.PortActivityId, 
        label: _lang=='en' ?  e.PortActivityNameEn : e.PortActivityNameEs,
      })    
    })
    return chart;
  }
}


function getTpOperation() {

  this.isLoadingTpOperation = true;

  this.$http.get("TpOperation-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.tpOperationList = response.data.data;
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTpClient = false;
  });
}

function tpOperationFormatted(){
  if(this.tpOperationList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.tpOperationList.map(function(e){
      chart.push({
        value: e.OperationId, 
        label: e.OperationName,
      })    
    })
    return chart;
  }
}

function setTpOperationActivity(newOperationActivityData) {


  this.actividadPortuaria.PortActivityTpOperationId = newOperationActivityData.PortActivityOperationId;
  this.actividadPortuaria.tpOperationId = newOperationActivityData.OperationId;
  this.actividadPortuaria.PortActivityId = newOperationActivityData.PortActivityId;
  this.actividadPortuaria.Status = newOperationActivityData.FgActPortActivityOperation?1:0;
  this.actividadPortuaria.statusOrigin = newOperationActivityData.FgActPortActivityOperation ? 1 : 0;
  this.actividadPortuaria.OperationNameEs = newOperationActivityData.OperationNameEs;
  this.titulo = this.$t('label.edit')+this.$t('label.OperationType')+': '+this.actividadPortuaria.OperationNameEs;
  this.$v.actividadPortuaria.$touch();
}

export default {
  name: 'modal-edit-tpoperation-port-activity',
  mixins: [ActividadPortuariaMixin, ModalMixin],
  props: {
    modal: Boolean,
    tpOperationActivityData: {
      type: Object,
      default: () => { }
    }
  },
  components: {
  },
  data,
  validations: tpOperationActividadValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getActivityPort,
    getTpOperation,
    statusSelectColor,
    changeStatus,
    setTpOperationActivity
  },
  computed: { 
        activityListFormatted,
        tpOperationFormatted,
        getTitle,
   },
  watch: {
    modal: function(val) {
      this.toggle(val);
      this.getActivityPort();
      this.getTpOperation();    
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);

    },
    tpOperationActivityData: function(newOperationActivityData) {
      this.setTpOperationActivity(newOperationActivityData);
    }
  }
}
</script>

<style scoped>
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.preview {
  height: 15em;
}
</style>